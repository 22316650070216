import * as React from "react";

// React Icons
import { TbEye, TbEyeOff } from "react-icons/tb";
import { IoMdHelpCircleOutline } from "react-icons/io";

// Components
import { FormLabel } from "../Form";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipPortal,
} from "../../common/Tooltip";

// Utils
import { cn } from "@/utils/functions/cn";
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fqcn_bui?: IFQCN_BUI;
  required?: boolean;
  helperText?: string;
  fieldIcon?: React.ReactNode;
  errorMessage?: string;
}

const default_fqcn = {
  Bundle: "defaultInputBundle",
  Unit: "input",
  Interface: "input",
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      className,
      type,
      fqcn_bui = default_fqcn,
      label,
      required = false,
      helperText = "",
      fieldIcon,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [tempType, setTempType] = React.useState(type);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const handleHelperClick = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setTooltipOpen(!tooltipOpen);
    };

    const handleTooltipOpenChange = (open: boolean) => {
      if (!open && !tooltipOpen) {
        return;
      }
    };

    return (
      <div className="flex flex-col gap-2">
        <div
          className={
            label || helperText
              ? "flex items-center justify-between w-full"
              : "hidden"
          }
        >
          {label && (
            <FormLabel className="flex items-center w-fit" htmlFor={id}>
              <span>{label}</span>
              {required && <span className="text-red-500 ml-1">*</span>}
            </FormLabel>
          )}

          {helperText && (
            <div
              className="justify-self-end cursor-pointer"
              id={getFQCN(fqcn_bui, "helper-icon")}
              onClick={handleHelperClick}
            >
              <TooltipProvider delayDuration={0} skipDelayDuration={0}>
                <Tooltip
                  open={tooltipOpen}
                  onOpenChange={handleTooltipOpenChange}
                >
                  <TooltipTrigger asChild>
                    <span className="text-sm text-muted-foreground">
                      <IoMdHelpCircleOutline fontSize={17} />
                    </span>
                  </TooltipTrigger>
                  <TooltipPortal>
                    <TooltipContent
                      id={getFQCN(fqcn_bui, "helper-tooltip")}
                      side="top"
                      align="end"
                      sideOffset={5}
                      className="z-[999] max-w-[350px]"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {helperText}
                    </TooltipContent>
                  </TooltipPortal>
                </Tooltip>
              </TooltipProvider>
            </div>
          )}
        </div>

        <div className="relative h-10">
          <input
            type={tempType}
            className={cn(
              "flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              className
            )}
            required={required}
            aria-required={required}
            ref={ref}
            {...props}
          />

          {type === "password" && (
            <div
              onClick={() => {
                if (!props.disabled) {
                  setTempType(tempType === "password" ? "text" : "password");
                } else {
                  setTempType("password");
                }
              }}
              className="absolute right-0 top-0 m-2.5 h-5 w-5 cursor-pointer transition-all duration-700 ease-in-out flex items-center justify-center"
            >
              {tempType === "password" ? (
                <TbEye
                  id={getFQCN(fqcn_bui, "password-login-icon")}
                  className={cn({
                    "cursor-not-allowed opacity-50": props.disabled,
                  })}
                />
              ) : (
                <TbEyeOff
                  id={getFQCN(fqcn_bui, "password-login-icon")}
                  className={cn({
                    "cursor-not-allowed opacity-50": props.disabled,
                  })}
                />
              )}
            </div>
          )}

          {fieldIcon && (
            <span className="absolute right-0 top-0 m-2.5 h-5 w-5 flex items-center justify-center">
              {fieldIcon}
            </span>
          )}
        </div>

        {errorMessage && (
          <p
            id={getFQCN(fqcn_bui, "input-error-message")}
            className={cn("text-sm font-medium text-destructive", className)}
          >
            {errorMessage}
          </p>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
