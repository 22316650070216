"use client";

import { useEffect, useState } from "react";

// Next
import { useRouter, useSearchParams } from "next/navigation";

// Cookie
import cookie from "js-cookie";

// Sentry
import * as Sentry from "@sentry/nextjs";

// React Icons
import { HiLogout, HiOutlineUser } from "react-icons/hi";

// React Hook Form
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

// Zod
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Components
import { Button } from "@/components/common/Button";
import { Input } from "@/components/FormElements/Input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/common/Tooltip";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { useAppDispatch, useAppSelector } from "@/utils/hooks/store";
import { loginThunk } from "@/store/thunks/authThunk";
import useAppTranslations from "@/utils/hooks/useAppTranslations";
import GetTenant from "@/utils/functions/getTenant";

const formSchema = z.object({
  username: z.string().min(1, {
    message: "Username must not be empty.",
  }),
  password: z.string().min(1, {
    message: "Password must not be empty.",
  }),
});

const fqcn_bui = {
  Bundle: "securityBundle",
  Unit: "login",
  Interface: "login",
};

type FormValues = {
  username: string;
  password: string;
};

const LoginForm = ({ params: { locale } }: { params: { locale: string } }) => {
  const [classes, setClasses] = useState("border-gray-300");
  const [errorMsg, setErrorMsg] = useState("");

  const { translate: t } = useAppTranslations();
  const router = useRouter();
  const searchParams = useSearchParams();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth.login);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = form;

  const onSubmit: SubmitHandler<FormValues> = async (values: FormValues) => {
    try {
      await dispatch(loginThunk(values)).unwrap();

      setClasses("border-gray-400 ring-2 ring-green-500 ring-offset-4");
      setErrorMsg("");

      if (cookie.get("currentUser")) {
        const returnUrl = searchParams?.get("returnUrl")
          ? searchParams?.get("returnUrl")?.replace(/[\[\]]/g, "")
          : locale === "fr"
          ? "/fr/dashboard"
          : "/en/dashboard";

        let returnQuery = "";
        if (window?.location?.search) {
          const match = window.location.search.match(
            /returnQuery=\[([^\]]+)\]/
          );
          if (match) {
            returnQuery = match[1]?.replace(/[\[\]]/g, "");
          }
        }

        if (returnQuery && !returnQuery.startsWith("?")) {
          returnQuery = "?" + returnQuery;
        }

        const redirectUrl = `${returnUrl}${returnQuery}`;
        router.push(redirectUrl);
      }
    } catch (err: any) {
      setClasses("border-gray-400 ring-2 ring-red-500 ring-offset-4");
      setErrorMsg(err?.message);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    form.setValue("username", searchParams.get("username") || "");
    form.setValue("password", searchParams.get("password") || "");

    if (searchParams.get("username") || searchParams.get("password")) {
      onSubmit({
        username: searchParams.get("username") || "",
        password: searchParams.get("password") || "",
      });
    }
  }, []);

  return (
    <>
      <h1
        id={getFQCN(fqcn_bui, "leftside-authform", "login-title")}
        className="text-3xl font-bold text-center"
      >
        {t("Log in")}
      </h1>

      <FormProvider {...form}>
        <form
          id={getFQCN(fqcn_bui, "leftside-authform")}
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8 mt-8"
        >
          <div className="relative w-full">
            <Input
              autoFocus
              id={getFQCN(fqcn_bui, "leftside-authform", "username-input")}
              className={classes}
              placeholder={t("Username")}
              disabled={isLoading}
              label={t("Username")}
              fieldIcon={
                <HiOutlineUser id={getFQCN(fqcn_bui, "user-login-icon")} />
              }
              errorMessage={t(errors.username?.message || "")}
              {...register("username")}
              helperText={t(
                `Enter the username or email provided by your corporation "${GetTenant()}" administrator. This is typically your work email address.`
              )}
            />
          </div>

          <div className="relative w-full">
            <Input
              type="password"
              className={classes}
              id={getFQCN(fqcn_bui, "leftside-authform", "password-input")}
              placeholder={t("Password")}
              disabled={isLoading}
              fqcn_bui={fqcn_bui}
              label={t("Password")}
              errorMessage={t(errors.password?.message || "")}
              {...register("password")}
              helperText={t(
                `Enter your corporation "${GetTenant()}" account password. Passwords are case-sensitive. If you've forgotten your password, please contact your system administrator.`
              )}
            />
          </div>

          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    id={getFQCN(fqcn_bui, "leftside-authform", "login-button")}
                    name="logIn-button"
                    type="submit"
                    variant="blue"
                    className="w-full text-xl"
                    disabled={isLoading}
                    isLoading={isLoading}
                    fqcn_bui={fqcn_bui}
                    iconEnd={
                      <HiLogout
                        id={getFQCN(fqcn_bui, "login-icon")}
                        className="h-6 w-6"
                      />
                    }
                  >
                    {t("Log in")}
                  </Button>
                </TooltipTrigger>

                <TooltipContent>
                  <p>{t("Log in")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <p className="text-red-500">{errorMsg}</p>
        </form>
      </FormProvider>
    </>
  );
};

export default LoginForm;
