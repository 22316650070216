"use client";

// React
import { useState } from "react";

// Next Intl
import { useLocale } from "next-intl";

// React Icons
import { HiOutlinePaperAirplane } from "react-icons/hi";

// Components
import { Button } from "@/components/common/Button";
import { LoginSvg } from "@/components/icons";
import Logo from "@/components/common/Logo";
import FeedbackModal from "@/components/common/FeedbackForm";
import LoginForm from "../Form";
import Layout from "@/components/Layout";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/common/Tooltip";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import useAppTranslations from "@/utils/hooks/useAppTranslations";

var fqcn_bui = {
  Bundle: "loginBundle",
  Unit: "form",
  Interface: "login",
};

const LoginContainer = () => {
  const [loginForm, setLoginForm] = useState(false);

  const locale = useLocale();

  const { translate: t } = useAppTranslations();
  return (
    <Layout>
      <FeedbackModal isOpen={loginForm} onClose={() => setLoginForm(false)} />

      <div className="grid md:grid-cols-2 page-height h-full">
        <div className="relative top-1/2 -translate-y-1/3 sm:py-16 py-0">
          <div className="flex place-content-center">
            <div className="w-4/5 2xl:w-3/5">
              <LoginForm
                params={{
                  locale: locale,
                }}
              />
            </div>
          </div>

          <div className="text-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    id={getFQCN(fqcn_bui, "problem-button")}
                    name="problem-button"
                    className="bg-slate-400 hover:bg-slate-500 mt-5 md:mt-0 lg:mt-5 mb-4"
                    onClick={() => setLoginForm(true)}
                  >
                    <HiOutlinePaperAirplane className="h-4 w-4" />
                    {t("Report a problem")}
                  </Button>
                </TooltipTrigger>

                <TooltipContent>
                  <p>{t("Report a problem")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <div className="left-side bg-phpc-blue dark:bg-phpc-blue-dark hidden md:block">
          <div className="flex place-content-center items-center justify-center h-full">
            <div className="h-3/5 w-3/5">
              <Logo
                fqcn_bui={{
                  Bundle: "securityBundle",
                  Unit: "login",
                  Interface: "login",
                }}
              />
              <LoginSvg />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LoginContainer;
